:root {
  --dark-bg-color: #222f38;

  --dark-color: #adb5be;

  --light-bg-color: #e4e4e4;

  --light-color: #1f292f;
}

.flatpickr-monthSelect-months {
  margin: 10px 1px 3px 1px;
  flex-wrap: wrap;
}

.flatpickr-monthSelect-month {
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  margin: 0.5px;
  justify-content: center;
  padding: 10px;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  width: 33%;
}

.flatpickr-monthSelect-month.flatpickr-disabled {
  color: #eee;
}

.flatpickr-monthSelect-month.flatpickr-disabled:hover,
.flatpickr-monthSelect-month.flatpickr-disabled:focus {
  cursor: not-allowed;
  background: none !important;
}

.flatpickr-monthSelect-theme-dark {
  background: #3f4458;
}

.flatpickr-monthSelect-theme-dark .flatpickr-current-month input.cur-year {
  color: #fff;
}

.flatpickr-monthSelect-theme-dark .flatpickr-months .flatpickr-prev-month,
.flatpickr-monthSelect-theme-dark .flatpickr-months .flatpickr-next-month {
  color: #fff;
  fill: #fff;
}

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month {
  color: rgba(255, 255, 255, 0.95);
}

.flatpickr-monthSelect-month.today {
  border-color: #959ea9;
}

.flatpickr-monthSelect-month.inRange,
.flatpickr-monthSelect-month.inRange.today,
.flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-month:focus {
  background: #e6e6e6;
  cursor: pointer;
  outline: 0;
  border-color: #e6e6e6;
}

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.inRange,
.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month:focus {
  background: #646c8c;
  border-color: #646c8c;
}

.flatpickr-monthSelect-month.today:hover,
.flatpickr-monthSelect-month.today:focus {
  background: #959ea9;
  border-color: #959ea9;
  color: #fff;
}

.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-month.endRange {
  background-color: #569ff7;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-monthSelect-month.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-monthSelect-month.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-monthSelect-month.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-monthSelect-month.inRange {
  border-radius: 0;
  box-shadow:
    -5px 0 0 #e6e6e6,
    5px 0 0 #e6e6e6;
}

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.endRange {
  background: #80cbc4;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #80cbc4;
}

@media all {
  ul {
    overflow-wrap: break-word;
  }
}

@media all {
  .clearfix:after,
  .clearfix:before {
    content: " ";
    display: table;
  }

  .clearfix:after {
    clear: both;
  }

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  ul {
    padding-left: 1.3em;
    line-height: inherit;
    margin: 0 0 20px;
  }

  ul {
    list-style: disc;
  }

  ul li {
    margin-bottom: 10px;
  }

  li ul,
  ul ul {
    margin-top: 10px;
  }

  nav ul {
    list-style: none !important;
    list-style-image: none;
    margin: 0;
    padding: 0;
  }
}

@media all {
  [class^="icon-"]:before {
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-icon_plus:before {
    content: "\e806";
  }

  .icon-arrow-right5:before {
    content: "\e6d0";
  }
}

@media all {
  header,
  nav {
    display: block;
  }

  a,
  div,
  i,
  img,
  li,
  span,
  ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  ul {
    list-style: none;
  }

  :focus {
    outline: 0;
  }

  img {
    max-width: 100%;
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
  }

  ::-moz-selection {
    background: #000;
    color: #fff;
  }

  ::selection {
    background: #000;
    color: #fff;
  }

  ::-moz-selection {
    background: #ffff9e;
  }

  img::selection {
    background: transparent;
  }

  img::-moz-selection {
    background: transparent;
  }

  #header-bottom-bar {
    line-height: 25px;
  }

  .logo a,
  .logo a img {
    display: block;
  }

  .style1 .logo {
    margin-left: 30px;
    padding: 20px 0;
    float: left;
  }

  .be-nav-link-effect-1 a {
    padding: 8px 0;
  }

  .be-nav-link-effect-1 a:after {
    position: absolute;
    top: 170%;
    left: 20px;
    width: calc(100% - 40px);
    height: 2px;
    background: rgba(0, 0, 0, 0.1);
    content: "";
    opacity: 0;
    -webkit-transition:
      opacity 0.3s,
      -webkit-transform 0.3s;
    -o-transition:
      opacity 0.3s,
      -o-transform 0.3s;
    transition:
      opacity 0.3s,
      -webkit-transform 0.3s;
    -o-transition:
      opacity 0.3s,
      transform 0.3s;
    transition:
      opacity 0.3s,
      transform 0.3s;
    transition:
      opacity 0.3s,
      transform 0.3s,
      -webkit-transform 0.3s;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
  }

  .be-nav-link-effect-1 a:hover:after {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  .sub-menu a:after {
    display: none;
  }

  #navigation .sub-menu a {
    left: 0;
    -o-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  #navigation .sub-menu a:hover {
    left: 7px;
  }

  .header-code-widgets {
    display: inline-block;
    margin-left: 15px;
  }

  body.header-transparent #header #header-inner-wrap.no-transparent {
    position: fixed;
    top: -300px;
    width: 100%;
    right: 0;
  }

  body.header-transparent
    #header
    #header-inner-wrap.no-transparent.top-animate {
    top: 0;
    -webkit-transition:
      top 0.5s ease,
      right 0.4s ease;
    -moz-transition:
      top 0.5s ease,
      right 0.4s ease;
    -o-transition:
      top 0.5s ease,
      right 0.4s ease;
    -ms-transition:
      top 0.5s ease,
      right 0.4s ease;
    -webkit-backface-visibility: hidden;
  }

  body.header-transparent #header #header-inner-wrap {
    position: absolute;
    width: 100%;
    border: none !important;
  }

  body.header-transparent #header #header-bottom-bar,
  body.header-transparent #header #header-wrap {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  #header-inner-wrap {
    position: relative;
  }

  .logo img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    opacity: 0;
    visibility: hidden;
  }

  .top-animate .logo .sticky-logo {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translateY(0) translateX(0);
    -ms-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: opacity 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  #header {
    position: relative;
    z-index: 10;
  }

  #header-inner-wrap.top-animate #navigation {
    line-height: 70px;
    -webkit-transition: line-height 0.5s ease;
    -o-transition: line-height 0.5s ease;
    transition: line-height 0.5s ease;
  }

  #navigation a {
    position: relative;
    color: inherit;
    padding: 0 20px;
    display: inline-block;
    line-height: normal;
  }

  #navigation ul li {
    float: left;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    line-height: inherit;
  }

  #navigation .sub-menu {
    position: absolute;
    width: 200px;
    display: none;
    z-index: 12;
    top: 100%;
    line-height: 32px;
    padding: 10px 0;
    margin-top: 1px;
  }

  #navigation ul .sub-menu li {
    display: block;
    float: none;
    text-align: left;
  }

  #navigation ul .sub-menu li a {
    display: block;
    padding: 5px 20px;
    line-height: inherit;
  }

  #navigation .mobile-sub-menu-controller {
    display: none;
  }

  .mobile-menu {
    display: none;
  }

  #mobile-menu,
  #mobile-menu ul {
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    text-align: left;
  }

  #mobile-menu ul.sub-menu {
    padding: 0 0 15px;
    display: none;
  }

  #mobile-menu li {
    margin-bottom: 0;
    position: relative;
    padding: 0 0 0 20px;
    border-bottom: 1px solid transparent;
  }

  #mobile-menu .sub-menu li {
    border: none;
    padding-right: 0;
  }

  #mobile-menu a {
    color: inherit;
    padding-right: 5px;
    display: inline-block;
    cursor: pointer;
  }

  #mobile-menu .mobile-sub-menu-controller {
    width: 50px;
    cursor: pointer;
    font-size: 13px;
    float: right;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    text-align: center;
  }

  .mobile-nav-controller-wrap {
    display: none;
  }

  .mobile-nav-controller-wrap .menu-controls {
    margin-left: 0;
    margin-right: 0;
  }

  .mobile-nav-controller {
    display: inline-block;
  }

  .be-mobile-menu-icon {
    display: inline-block;
    position: relative;
    -webkit-transform: translateX(0) translateY(-50%);
    -ms-transform: translateX(0) translateY(-50%);
    transform: translateX(0) translateY(-50%);
    width: 18px;
    height: 2px;
    top: -4px;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    visibility: hidden;
    user-select: none;
  }

  .be-mobile-menu-icon .hamburger-line-1 {
    top: -5px;
  }

  .be-mobile-menu-icon .hamburger-line-3,
  .be-mobile-menu-icon:after {
    top: 5px;
  }

  .be-mobile-menu-icon.is-clicked {
    background-color: hsla(0, 0%, 100%, 0) !important;
  }

  .be-mobile-menu-icon.is-clicked .hamburger-line-1,
  .be-mobile-menu-icon.is-clicked:before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .be-mobile-menu-icon.is-clicked .hamburger-line-3,
  .be-mobile-menu-icon.is-clicked:after {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .be-mobile-menu-icon span {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: inherit;
    right: 0;
    -webkit-transition:
      top 0.5s,
      width 0.2s,
      -webkit-transform 0.5s;
    transition:
      top 0.5s,
      width 0.2s,
      -webkit-transform 0.5s;
    -o-transition:
      transform 0.5s,
      top 0.5s,
      width 0.2s;
    transition:
      transform 0.5s,
      top 0.5s,
      width 0.2s;
    transition:
      transform 0.5s,
      top 0.5s,
      width 0.2s,
      -webkit-transform 0.5s;
    visibility: visible;
  }

  .be-mobile-menu-icon.style2 .hamburger-line-1,
  .be-mobile-menu-icon.style2 .hamburger-line-3 {
    width: 50%;
  }

  .be-mobile-menu-icon.is-clicked .hamburger-line-1,
  .be-mobile-menu-icon.is-clicked .hamburger-line-2,
  .be-mobile-menu-icon.is-clicked .hamburger-line-3,
  .menu-controls:hover .be-mobile-menu-icon span {
    width: 100%;
  }

  .be-mobile-menu-icon.is-clicked .hamburger-line-2 {
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
  }

  a,
  a:hover,
  a:visited {
    text-decoration: none;
  }

  ul {
    padding-left: 1.3em;
    line-height: inherit;
    margin: 0 0 20px;
  }

  ul {
    list-style: disc;
  }

  ul li {
    margin-bottom: 10px;
  }

  li ul,
  ul ul {
    margin-top: 10px;
  }

  nav ul {
    list-style: none !important;
    list-style-image: none;
    margin: 0;
    padding: 0;
  }

  /* ::-webkit-input-placeholder {
color: inherit !important;
font: inherit !important;
} */

  #navigation .sub-menu a:before,
  #navigation a:before {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    max-width: 0;
    -webkit-transition: max-width 0.5s cubic-bezier(0.2, 0.7, 0.3, 1) 80ms;
    -o-transition: max-width 0.5s cubic-bezier(0.2, 0.7, 0.3, 1) 80ms;
    transition: max-width 0.5s cubic-bezier(0.2, 0.7, 0.3, 1) 80ms;
  }

  #navigation .sub-menu a:hover:before,
  #navigation a:hover:before {
    max-width: 100%;
  }

  #header-wrap {
    -webkit-transition: background 0.7s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    -o-transition: background 0.7s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    transition: background 0.7s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }

  .be-mobile-menu-icon span {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: inherit;
    right: 0;
    -webkit-transition:
      top 0.5s,
      width 0.2s,
      -webkit-transform 0.5s;
    transition:
      top 0.5s,
      width 0.2s,
      -webkit-transform 0.5s;
    -o-transition:
      transform 0.5s,
      top 0.5s,
      width 0.2s;
    transition:
      transform 0.5s,
      top 0.5s,
      width 0.2s;
    transition:
      transform 0.5s,
      top 0.5s,
      width 0.2s,
      -webkit-transform 0.5s;
    visibility: visible;
  }

  .be-mobile-menu-icon.style2 .hamburger-line-1,
  .be-mobile-menu-icon.style2 .hamburger-line-3 {
    width: 50%;
  }

  .menu-controls:hover .be-mobile-menu-icon span {
    width: 100%;
  }

  @media only screen and (max-width: 960px) {
    #header-bottom-bar {
      display: none !important;
    }

    body.header-transparent #header #header-inner-wrap.no-transparent {
      position: absolute;
      left: auto;
      right: auto;
    }

    .mobile-nav-controller-wrap {
      display: inline-block;
      margin-left: 15px;
      cursor: pointer;
    }
  }

  .clearfix:after,
  .clearfix:before {
    content: " ";
    display: table;
  }

  .clearfix:after {
    clear: both;
  }

  .clearfix {
    *zoom: 1;
  }

  @media print {
    * {
      background: transparent !important;
      color: #000 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: " (" attr(href) ")";
    }

    a[href^="#"]:after {
      content: "";
    }

    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }
  }
}

@media all {
  .style1 #header-controls-right {
    float: right;
    margin-right: 30px;
  }

  .style1 #navigation {
    float: right;
    margin-right: 30px;
  }

  body.header-transparent #navigation .sub-menu {
    top: 100%;
  }

  @media only screen and (max-width: 960px) {
    body.header-transparent #header #header-inner-wrap.no-transparent {
      position: absolute;
      left: auto;
      right: auto;
    }

    .mobile-nav-controller-wrap {
      display: inline-block;
    }
  }
}

/*! CSS Used from: https://gold-im.com/wp-content/themes/oshin/css/headers/responsive-header.min.css?ver=7.0.6 ; media=all */
@media all {
  @media only screen and (max-width: 960px) {
    #navigation {
      display: none !important;
    }

    .style1 #header-controls-right {
      padding-left: 0;
    }
  }
}

@media all {
  #header-inner-wrap,
  body.header-transparent #header #header-inner-wrap.no-transparent {
    background-color: rgb(232, 232, 231);
    background-color: rgba(232, 232, 231, 1);
  }

  #mobile-menu,
  #mobile-menu ul {
    background-color: rgb(21, 32, 40);
    background-color: rgba(21, 32, 40, 1);
  }

  #mobile-menu li {
    border-bottom-color: transparent;
  }

  body.header-transparent #header-inner-wrap {
    background: transparent;
  }

  #header-bottom-bar {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 1);
    border-top: 0 none #323232;
    border-bottom: 0 none #323232;
  }

  body.header-transparent #header #header-inner-wrap {
    -webkit-transition:
      background 0.25s ease,
      box-shadow 0.25s ease,
      opacity 700ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 700ms cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition:
      background 0.25s ease,
      box-shadow 0.25s ease,
      opacity 700ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 700ms cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition:
      background 0.25s ease,
      box-shadow 0.25s ease,
      opacity 700ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 700ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition:
      background 0.25s ease,
      box-shadow 0.25s ease,
      opacity 700ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 700ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  body.header-transparent.semi #header .semi-transparent {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
  }

  #navigation .sub-menu {
    background-color: rgb(10, 13, 16);
    background-color: rgba(10, 13, 16, 1);
  }

  ul#mobile-menu a {
    font:
      18px "Crimson Text",
      "Open Sans",
      "Arial",
      sans-serif;
    color: #fff;
    line-height: 30px;
    letter-spacing: 1px;
    text-transform: capitalize;
  }

  ul#mobile-menu ul.sub-menu a {
    font:
      400 13px "Poppins",
      "Open Sans",
      "Arial",
      sans-serif;
    color: #888;
    line-height: 27px;
    letter-spacing: 0;
    text-transform: none;
  }

  #navigation,
  .header-code-widgets {
    font:
      20px "Crimson Text",
      "Open Sans",
      "Arial",
      sans-serif;
    color: #fff;
    line-height: 50px;
    letter-spacing: 1px;
    text-transform: capitalize;
  }

  #navigation .sub-menu {
    font:
      400 13px "Poppins",
      "Open Sans",
      "Arial",
      sans-serif;
    color: #67674b;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }

  #header-controls-right {
    color: #fff;
  }

  #mobile-menu .mobile-sub-menu-controller {
    width: 30px;
    font-size: 18px;
  }

  ul#mobile-menu .mobile-sub-menu-controller {
    line-height: 30px;
  }

  ul#mobile-menu .menu-item-has-children a {
    user-select: none;
  }

  #mobile-menu .menu-item-has-children.is-clicked .mobile-sub-menu-controller {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  #mobile-menu .menu-item-has-children.is-clicked .sub-menu {
    display: block !important;
  }

  #header .be-mobile-menu-icon span {
    background-color: #323232;
  }

  #header-controls-right {
    color: #323232;
  }

  .be-mobile-menu-icon {
    width: 22px;
    height: 2px;
  }

  .be-mobile-menu-icon .hamburger-line-1 {
    top: -7px;
  }

  .be-mobile-menu-icon .hamburger-line-3 {
    top: 7px;
  }

  a,
  a:visited,
  a:hover {
    color: #8fbdd7;
  }

  #navigation .current_page_item a,
  #navigation .current_page_item a:hover,
  #navigation a:hover,
  #navigation .current-menu-item > a,
  #navigation .sub-menu a:hover {
    color: #8fbdd7;
  }

  .be-nav-link-effect-1 a::after {
    background-color: rgb(143, 189, 215);
    background-color: rgba(143, 189, 215, 1);
  }

  body #header-inner-wrap.top-animate #navigation {
    -webkit-transition: line-height 0.5s ease;
    -moz-transition: line-height 0.5s ease;
    -ms-transition: line-height 0.5s ease;
    -o-transition: line-height 0.5s ease;
    transition: line-height 0.5s ease;
  }

  .style1 .logo {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  a,
  a:visited {
    color: #8fbdd7;
  }

  .style1 #navigation,
  #header-controls-right,
  #header-wrap,
  .mobile-nav-controller-wrap {
    line-height: 128px;
  }

  body.header-transparent #header-wrap #navigation,
  body.header-transparent #header-inner-wrap #header-controls-right,
  body.header-transparent #header-inner-wrap #header-wrap,
  body.header-transparent #header-inner-wrap .mobile-nav-controller-wrap {
    line-height: 128px;
  }

  body #header-inner-wrap.top-animate #navigation,
  body #header-inner-wrap.top-animate #header-wrap,
  body #header-inner-wrap.top-animate #header-controls-right {
    line-height: 128px;
  }

  @media only screen and (max-width: 320px) {
    .logo {
      width: 230px;
      max-width: 40%;
      margin-left: 10px !important;
    }

    #header-controls-right,
    .mobile-nav-controller-wrap {
      line-height: 102.78260869565px !important;
      right: 10px !important;
    }
  }
}

#navigation a::before,
#navigation .sub-menu a::before {
  color: #fff;
}

#header-inner-wrap,
body.header-transparent #header #header-inner-wrap.no-transparent {
  background-color: rgba(13, 24, 32, 1);
}

#header .be-mobile-menu-icon span {
  background-color: #fff;
}

@media only screen and (min-width: 481px) {
  .top-animate .logo .sticky-logo {
    top: 5px;
  }
}

@media only screen and (max-width: 600px) {
  .header-transparent #header-inner-wrap {
    background: rgba(13, 24, 32, 1) !important;
  }
}

.be-mobile-menu-icon span {
  right: 0 !important;
  left: auto !important;
}

#navigation {
  font:
    16px "Crimson Text",
    "Open Sans",
    "Arial",
    sans-serif;
}

.be-nav-link-effect-1 a:after {
  top: 140% !important;
  height: 0px !important;
}

.be-nav-link-effect-1 a::after {
  background-color: rgb(255, 255, 255) !important;
  background-color: rgba(255, 255, 255, 1) !important;
}

.header-code-widgets {
  margin: 0px 5px 0px 25px !important;
}

.header-code-widgets {
  font:
    15px "Poppins",
    "Open Sans",
    "Arial",
    sans-serif !important;
  text-transform: uppercase !important;
}

#mobile-menu,
#mobile-menu ul {
  background-color: rgb(13, 24, 32);
  background-color: rgba(13, 24, 32, 1);
}

#mobile-menu,
#mobile-menu ul {
  padding: 40px 0px 80px 0px;
  text-align: center;
  line-height: 60px;
}

#mobile-menu li {
  padding: 0 !important;
}

#header-controls-right,
.mobile-nav-controller-wrap {
  line-height: 80px !important;
  right: 20px !important;
}

@media only screen and (max-width: 480px) and (min-width: 321px) {
  .header-code-widgets {
    margin: 0px 10px 0px 10px !important;
  }

  .logo {
    max-width: 45% !important;
  }
}

.logo {
  width: 230px;
  height: auto;
}

#header-inner-wrap #header-controls-right {
  line-height: 65px !important;
}

#header-controls-right,
.mobile-nav-controller-wrap {
  line-height: 65px !important;
  right: 20px !important;
}

.style1 .logo {
  padding-top: 5px;
  padding-bottom: 0px;
}

#header-wrap #navigation {
  line-height: 80px !important;
}

body #header-inner-wrap.top-animate #navigation {
  line-height: 80px !important;
}

#header-inner-wrap.top-animate #header-controls-right {
  line-height: 80px !important;
}

#header-controls-right {
  color: #fff !important;
}

#navigation a {
  padding: 0 15px;
}

#menu-item-610 a {
  font-weight: 900;
}

#navigation a:hover {
  color: #8fbdd7;
}

.style1 #header-controls-right {
  float: right;
  margin-right: 25px;
}

.style1 #navigation {
  float: right;
  margin-right: -30px !important;
}

@media only screen and (max-width: 767px) {
  #header {
    position: fixed !important;
    width: 100%;
  }
}

.page-id-121 #menu-item-799 {
  display: none !important;
}

#navigation {
  text-transform: none;
}

#navigation .sub-menu {
  margin-top: 0px;
  color: #fff;
  background-color: rgba(13, 24, 32, 1);
}

#mobile-menu .mobile-sub-menu-controller {
  width: 30px;
  font-size: 18px;
}

ul#mobile-menu .mobile-sub-menu-controller {
  line-height: inherit;
}

#mobile-menu li.menu-item-has-children {
  margin-left: 30px;
}

#mobile-menu,
#mobile-menu ul {
  line-height: 40px;
}

#mobile-menu ul.sub-menu {
  padding-top: 1px;
}

#mobile-menu ul {
  margin-left: -30px !important;
}

#menu {
  margin-right: 30px;
}

#navigation ul .sub-menu li a {
  padding: 3px 20px;
  line-height: 1em;
}

#navigation .sub-menu {
  margin-top: -30px;
}

ul#mobile-menu ul.sub-menu a {
  font-size: 14px;
  color: #fff;
}

@font-face {
  font-family: "icomoon";
  src: url(https://gold-im.com/wp-content/themes/oshin/fonts/icomoon/fonts/icomoon.eot?85pf5i);
  src:
    url(https://gold-im.com/wp-content/themes/oshin/fonts/icomoon/fonts/icomoon.eot#iefix85pf5i)
      format("embedded-opentype"),
    url(https://gold-im.com/wp-content/themes/oshin/fonts/icomoon/fonts/icomoon.woff?85pf5i)
      format("woff"),
    url(https://gold-im.com/wp-content/themes/oshin/fonts/icomoon/fonts/icomoon.ttf?85pf5i)
      format("truetype"),
    url(https://gold-im.com/wp-content/themes/oshin/fonts/icomoon/fonts/icomoon.svg?85pf5i#icomoon)
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Crimson Text";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/crimsontext/v14/wlpogwHKFkZgtmSR3NB0oRJfajhRK_Z_3rhH.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Crimson Text";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/crimsontext/v14/wlp2gwHKFkZgtmSR3NB0oRJfbwhTIfFd3A.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Crimson Text";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/crimsontext/v14/wlp2gwHKFkZgtmSR3NB0oRJfbwhTIfFd3A.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@media only screen and (min-width: 961px) {
  .mobile-menu {
    display: none !important;
  }
}

/* additions */
@media only screen and (max-width: 960px) {
  #wrapper .content-page {
    margin-top: 80px !important;
    margin-bottom: 50px !important;
  }

  #wrapper #header-inner-wrap {
    height: 65px !important;
  }

  #wrapper #header-inner-wrap.top-animate #header-controls-right {
    line-height: 65px !important;
  }

  #wrapper #header-inner-wrap .sticky-logo {
    top: 5px !important;
  }
}
/**
* General Uppy styles that apply to everything inside the .uppy-Root container
*/
.uppy-Root {
  position: relative;
  box-sizing: border-box;
  color: #333;
  font-family: -apple-system, blinkmacsystemfont, "Segoe UI", helvetica, arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

[dir="rtl"] .uppy-Root,
.uppy-Root[dir="rtl"] {
  text-align: right;
}

.uppy-Root *,
.uppy-Root *::before,
.uppy-Root *::after {
  box-sizing: inherit;
}

.uppy-Root [hidden] {
  display: none;
}

.uppy-u-reset {
  top: auto;
  left: auto;
  z-index: auto;
  display: inline;
  float: none;
  clear: none;
  min-width: 0;
  max-width: none;
  min-height: 0;
  max-height: none;
  margin: 0;
  padding: 0;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  color: inherit;
  font-weight: normal;
  font-size: inherit;
  font-family: inherit;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  white-space: normal;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  vertical-align: baseline;
  -webkit-hyphens: none;
  hyphens: none;
  unicode-bidi: normal;
  list-style: none;
  empty-cells: show;
  background: none;
  border: 0;
  border: medium none currentColor;
  border-radius: 0;
  border-image: none;
  border-collapse: separate;
  border-spacing: 0;
  outline: medium none invert;
  box-shadow: none;
  transform: none;
  transform-origin: 50% 50% 0;
  transform-style: flat;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  visibility: visible;
  cursor: auto;
  opacity: 1;
  transition: none 0s ease 0s;
  -webkit-appearance: none;
}

[dir="rtl"] .uppy-u-reset {
  text-align: right;
}

.uppy-c-textInput {
  padding: 6px 8px;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.uppy-size--md .uppy-c-textInput {
  padding: 8px 10px;
}

.uppy-c-textInput:focus {
  border-color: rgba(34, 117, 215, 0.6);
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.15);
}

[data-uppy-theme="dark"] .uppy-c-textInput {
  color: #eaeaea;
  background-color: #333;
  border-color: #333;
}

[data-uppy-theme="dark"] .uppy-c-textInput:focus {
  border-color: #525252;
  box-shadow: none;
}

.uppy-c-icon {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  fill: currentColor;
}

.uppy-c-btn {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  font-family: inherit;
  line-height: 1;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  transition-duration: 0.3s;
  transition-property: background-color, color;
  -webkit-user-select: none;
  user-select: none;
}

[dir="rtl"] .uppy-c-btn {
  text-align: center;
}

.uppy-c-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.uppy-c-btn::-moz-focus-inner {
  border: 0;
}

.uppy-c-btn-primary {
  padding: 10px 18px;
  color: #fff;
  font-size: 14px;
  background-color: #2275d7;
  border-radius: 4px;
}

.uppy-c-btn-primary:hover {
  background-color: #1b5dab;
}

.uppy-c-btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.4);
}

.uppy-size--md .uppy-c-btn-primary {
  padding: 13px 22px;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary {
  color: #eaeaea;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}

.uppy-c-btn-link {
  padding: 10px 15px;
  color: #525252;
  font-size: 14px;
  line-height: 1;
  background-color: transparent;
  border-radius: 4px;
}

.uppy-c-btn-link:hover {
  color: #333;
}

.uppy-c-btn-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.25);
}

.uppy-size--md .uppy-c-btn-link {
  padding: 13px 18px;
}

[data-uppy-theme="dark"] .uppy-c-btn-link {
  color: #eaeaea;
}

[data-uppy-theme="dark"] .uppy-c-btn-link:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-c-btn-link::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-c-btn-link:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}

[data-uppy-theme="dark"] .uppy-c-btn-link:hover {
  color: #939393;
}

.uppy-c-btn--small {
  padding: 7px 16px;
  font-size: 0.9em;
  border-radius: 2px;
}

.uppy-size--md .uppy-c-btn--small {
  padding: 8px 10px;
  border-radius: 2px;
}

.uppy-DragDrop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  font-family: -apple-system, blinkmacsystemfont, "Segoe UI", helvetica, arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #fff;
  border-radius: 7px;
  cursor: pointer;
}

.uppy-DragDrop-container::-moz-focus-inner {
  border: 0;
}

.uppy-DragDrop-container:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.4);
}

.uppy-DragDrop-inner {
  margin: 0;
  padding: 80px 20px;
  line-height: 1.4;
  text-align: center;
}

.uppy-DragDrop-arrow {
  width: 60px;
  height: 60px;
  margin-bottom: 17px;
  fill: #e0e0e0;
}

.uppy-DragDrop--isDragDropSupported {
  /* border: 2px dashed #adadad; */
}

.uppy-DragDrop--isDraggingOver {
  background: #eaeaea;
  border: 2px dashed #2275d7;
}

.uppy-DragDrop--isDraggingOver .uppy-DragDrop-arrow {
  fill: #939393;
}

.uppy-DragDrop-label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.15em;
}

.uppy-DragDrop-browse {
  color: #2275d7;
  cursor: pointer;
}

.uppy-DragDrop-note {
  color: #adadad;
  font-size: 1em;
}

.uppy-ProgressBar {
  /* no important */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 3px;
  transition: height 0.2s;
}

.uppy-ProgressBar[aria-hidden="true"] {
  /* no important */
  height: 0;
}

.uppy-ProgressBar-inner {
  width: 0;
  height: 100%;
  /* no important */
  background-color: #2275d7;
  box-shadow: 0 0 10px rgba(34, 117, 215, 0.7);
  transition: width 0.4s ease;
}

.uppy-ProgressBar-percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  /* no important */
  display: none;
  color: #fff;
  text-align: center;
  transform: translate(-50%, -50%);
}
.fileUpload label {
  width: 100%;
  height: 100%;
  border: 2px dashed #adadad;
}
.error {
  color: red;
}

.overlay {
  margin: 0px;
  padding: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(133, 131, 131);
  z-index: 30001;
  opacity: 0.7;
  pointer-events: none;
}

.spinner {
  position: absolute;
  color: #000000 !important;
  top: 35%;
  left: 48%;
}

.checkBox1 button {
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 5px;
  width: 60px;
  cursor: pointer !important;
  background: #d4d9dd;
  border: 0px;
  padding: 5px 10px;
}
.selectedYesBtn {
  background: #1abc9c !important;
  color: #fff;
}
.selectedNoBtn {
  background: #323a46 !important;
  color: #fff;
}

.swal-button--confirm {
  background-color: #1abc9c !important;
}

.fileName {
  color: #1b5dab;
  cursor: pointer;
}
.fileName:hover {
  text-decoration: underline;
}
